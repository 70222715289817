#feature {
  .root {
    position: relative
  }

  .decoration {
    position: absolute;
    width: 1280px;
    height: 100%;
    @include left(-10px);
    top: 100px;
    svg {
      width: 100%;
      height: 1700px;
      @include use-theme(fill, $palette-primary-light, $palette-primary-dark);
      opacity: 0.2;
      transform: scale(1.3);
      @media only screen and (min-width: 1400px) {
        transform: scale(2.5, 1);
      }
      @include breakpoints-up(xl) {
        display: none
      }
      @include breakpoints-down(xs) {
        transform: scale(0.5);
        transform-origin: center left
      }
    }
  }

  .item {
    position: relative;
    min-height: 320px;
    margin-bottom: $spacing20;
    .desc {
      position: relative;
      z-index: 60
    }
    &.last {
      margin-bottom: $spacing10
    }
    @include breakpoints-down(md) {
      margin-bottom: $spacing15
    }
    @include breakpoints-down(sm) {
      text-align: center
    }
    h6 {
      margin-bottom: $spacing5
    }
    .parallax-screen {
      @include breakpoints-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      @include breakpoints-down(sm) {
        margin: 0
      }
      .viewport  {
        perspective: 1000px
      }
    }
    .figure {
      transform-style: preserve-3d;
      overflow: hidden;
      @include shadow3;
      border-radius: $rounded-medium;
      background: $palette-common-white;
      padding: $spacing1;
      padding-top: $spacing3;
      margin-top: $spacing3;
      @include breakpoints-up(md) {
        margin: $spacing3;
      }
      img {
        width: 100%
      }
    }
  }

  .screen {
    position: relative;
    img {
      width: 90%;
      display: block
    }
  }
  .graphic {
    position: relative;
    img {
      width: 90%;
      display: block
    }
  }

  .tabs {
    position: relative;
    z-index: 55;
    margin-top: $spacing5 * -1;
    max-width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid;
    @include use-theme(border-color, $light-text-disabled,  $dark-text-disabled);
  }

  .tabs-content {
    position: relative;
    display: block;
    margin: $spacing5 auto 0;
    height: 250px !important;
    overflow: visible;
    @include breakpoints-up(sm) {
      height: 550px !important;
      padding: spacing(8, 4, 0)
    }
    section {
      position: relative;
      div {
        overflow: visible
      }
    }
  }

  .tab-label {
    font-size: 18px;
    font-weight: $font-medium;
    letter-spacing: 0;
    padding: spacing(0, 5);
    @include breakpoints-down(xs) {
      font-size: 14px;
    }
    .theme--dark & {
      color: $palette-primary-light
    }
  }

  .illustration-left {
    position: relative;
    z-index: 1;
    max-height: 600px;
    height: 100%;
    @include breakpoints-up(md) {
      top: -220px;
    }
    .screen {
      @include breakpoints-up(md) {
        margin-top: 100px;
        [dir="ltr"] & {
          transform: rotateY(30deg)
        }
        [dir="rtl"] & {
          transform: rotateY(-30deg)
        }
      }
    }
    .graphic {
      top: 280px;
      @include left(70px);
      width: 360px;
      @include breakpoints-up(md) {
        [dir="ltr"] & {
          transform: rotateY( 30deg ) rotateX(-5deg) rotateZ(0deg)
        }
        [dir="rtl"] & {
          transform: rotateY( -30deg ) rotateX(-5deg) rotateZ(0deg)
        }
      }
    }
  }

  .illustration-right {
    position: relative;
    z-index: 2;
    top: 0;
    @include breakpoints-up(md) {
      top: -250px;
    }
    .screen {
      @include breakpoints-up(md) {
        @include left(-20px);
        top: 240px;
        [dir="ltr"] & {
          transform: rotateY(-30deg)
        }
        [dir="rtl"] & {
          transform: rotateY(30deg)
        }
      }
    }
    .graphic {
      @include right(-30px);
      top: 420px;
      width: 400px;
      @media screen and (min-width: 1400px) {
        top: 450px;
      }
      @include breakpoints-up(md) {
        transform-style: preserve-3d;
        [dir="ltr"] & {
          transform: rotateY(-19deg) rotateX(1deg) rotateZ(-2deg)
        }
        [dir="rtl"] & {
          transform: rotateY(19deg) rotateX(1deg) rotateZ(-2deg)
        }
      }
    }
  }

  .illustration-center {
    perspective: 1000px;
    .figure.screen {
      display: block;
      text-align: center;
      max-width: 360px;
      margin: -50px auto 0;
      transform: rotateY( 0 ) rotateX(35deg) rotateZ(0deg);
      @include breakpoints-up(sm) {
        max-width: 700px;
      }
      img {
        margin: 0 auto;
        width: 100%
      }
    }
  }
}

.icon-featured {
  .root {
    text-align: center;
    @include palette-text-secondary;
    h5 {
      margin-bottom: $spacing3
    }
    p {
      padding: spacing(0, 4);
      margin-bottom: 0;
    }
  }
  .icon {
    width: 80px;
    height: 80px;
    font-size: 80px;
    margin: 0 auto;
    color: $palette-primary-light
  }
  .divider {
    max-height: none;
    margin: $spacing3 auto;
    width: 80px;
    height: 5px;
    background: $palette-primary-light
  }

  .use-text-subtitle {
    font-size: 24px;
    line-height: 36px;
  }
}

.teams {
  .item {
    padding: 20px;
    .inner {
      text-align: center;
      border-radius: 40px !important;
      overflow: hidden;
      background: transparent;
      padding: 20px 10px;
      background-color: rgba($color: #ffffff, $alpha: 0.4);
      box-shadow: 0 0.5px 5px 0px rgba(0, 0, 0, 0.1);
      @include breakpoints-down(xs) {
        box-shadow: none
      }
      .name {
        font-size: 18px;
        font-weight: var(--font-medium);
        span {
          margin-top: 4px;
          font-size: 14px;
          display: block;
        }
      }
  
      .avatar {
        width: 85px;
        height: 85px;
        margin: 0 auto;
        margin-bottom: 24px;
      }
    }
  }
}
$error-deco: '../images/saas/error-deco.png';

#main-wrap .error-wrap {
  width: 100%;
  min-height: 90vh;
  display: flex;
  margin-top: $spacing10;
  align-items: center;
  padding: spacing(20, 0, 10);
  > .container {
    width: 100%;
    margin: 0 auto;
  }
  .flex {
    display: flex;
    justify-content: center
  }

  .deco {
    position: relative;
    h3 {
      color: $palette-primary-dark;
      font-size: 106px;
      text-transform: capitalize;
      font-weight: 700;
      padding-top: 40px;
      @include padding-left(20px);
      position: relative;
      z-index: 1
    }
    &:before {
      content: "";
      width: 320px;
      height: 230px;
      background: url($error-deco) no-repeat;
      position: absolute;
      top: $spacing3 * -1;
      @include left(-40px);
      @include breakpoints-down(xs) {
        transform: scale(0.9);
        @include left(-48px)
      }
    }
  }

  .text {
    color: $palette-common-white;
    @include breakpoints-up(sm) {
      @include padding-left($spacing5);
      @include border-left(1px solid);
      @include use-theme(border-left-color, $light-divider, $dark-divider)
    }
    @include breakpoints-down(sm) {
      text-align: center;
      margin: spacing(5, 0);
    }
    h4 {
      color: $palette-common-white;
      font-weight: $font-bold;
      margin-bottom: $spacing4
    }
  }

  .button {
    margin-top: $spacing4
  }
}
